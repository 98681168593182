import Vue from "vue";
import StudioApp from "./StudioApp.vue";
import VueHotkey from "v-hotkey";
import VueRouter from "vue-router";
import routes from "../routes/studio.js";
import { Auth0Plugin } from "../plugins/auth0.js";
import store from "../stores/studio";
import axios from "axios";
import Vuetify from "vuetify";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import Tracker from "../services/tracker.js";
import { initDatadog } from '../datadog';


Vue.config.productionTip = false

// configure axios default behaviour
axios.defaults.headers.common['x-matt-component'] = "studio";
axios.defaults.headers.common['x-matt-app'] = "studio";

initDatadog();

const router = new VueRouter({ routes });
Vue.use(VueRouter);


// add in hotkey plugin
Vue.use(VueHotkey);


// configure vuetify
Vue.use(Vuetify)
const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#00bcd4',
        secondary: '#4f347c'
      }
    }
  }
}
const vuetify = new Vuetify(opts)


// configure auth0
const baseAppUrl = `${window.location.origin}/studio`;
Vue.use(Auth0Plugin, {
  store,
  router,
  domain: window.AUTH0_LOGIN_DOMAIN,
  clientId: window.AUTH0_LOGIN_CLIENT_ID,
  redirectUri: baseAppUrl,
  cacheLocation: 'localstorage',
  onRedirectCallback: () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('code');
    searchParams.delete('state');
    window.history.replaceState({}, document.title, `${window.location.pathname}${searchParams.toString()}`);
    router.push({name: 'home'}).catch(() => {});
  },
});


// create the vue instance
new Vue({
  vuetify,
  router,
  store,
  render: (createElement) => createElement(StudioApp),
}).$mount("#app");


// mvp translations
const translations = {
  "pathway": "section",
  "section": "module",
  "module": "unit"
}
Vue.prototype.PATHWAY = translations.pathway;
Vue.prototype.SECTION = translations.section;
Vue.prototype.MODULE = translations.module;
Vue.prototype.t = (key) => translations[key];
Vue.prototype.appName = 'studio';

// configure custom engagement tracking
Tracker.$auth = Vue.prototype.$auth;
router.afterEach((to, from) => {
  // todo log to datadog
  if(!from.name || to.name === from.name) return;
  Tracker.leavePage(from);
});