import {format} from "date-fns";

export const toKebabCase = (str) => {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export const toTitleCase = (str) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').trim();
}

export const toTitleCaseForId = (str) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('').trim();
}

export const toCamelCase = (str) => {
  return lowerCaseFirst(str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, ''));
}

export const lowerCaseFirst = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function formatDateTime(date) {
  if (!date) return '-';
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatDate(date) {
  if (!date) return '-';
  return format(typeof date === 'string' ? new Date(date) : date, 'y-MM-dd');
}

export function formatDisplayDate(date) {
  if(!date) return '-';
  return format(typeof date === 'string' ? new Date(date) : date, 'do MMM yyyy');
}

export function formatLongDisplayDate(date) {
  if(!date) return '-';
  return format(typeof date === 'string' ? new Date(date) : date, 'iii, do MMMM yyyy');
}

export function formatDuration2(seconds) {
  if (!seconds) return '-';
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
}

export function formatDuration(seconds) {
  if (!seconds) return '-';
  const minutes = Math.round(seconds / 60);

  if(seconds < 60) {
    return '<1m';
  }

  return `${minutes}m`;
}

export function formatDisplayTime(date) {
  if (!date) return '-';
  return format(typeof date === 'string' ? new Date(date) : date, 'p');
}

export function formatDisplayDateTime(date) {
  if (!date) return '-';
  const _date = formatDisplayDate(date);
  const _time = formatDisplayTime(date);
  return `${_date} ${_time}`;
}

export function formatTime(date) {
  if (!date) return '-';
  return format(typeof date === 'string' ? new Date(date) : date, 'HH:mm:ss');
}
