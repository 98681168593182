<template>
  <v-app>
    <v-main class="main" :style="mainStyle">
      <div class="overlay">
        <v-container class="d-flex align-start justify-center pt-16" style="min-height: 100vh;">
          <a href="https://www.gradussoftware.com.au/" target="_gradus" rel="noopener noreferrer">
            <img :src="`/logo/gradus/square-multi.svg`" class="logo right" alt="Gradus Software logo">
          </a>
          <a href="https://musicapprentice.com.au/studio" target="_ma" rel="noopener noreferrer">
            <img :src="`/logo/ma/square-purple.svg`" class="logo left" alt="Music Apprentice logo">
          </a>

          <div class="login-box text-center">
            <h1 class="headerText">Mozart's Sister - Study Guide</h1>
            
            <div class="info-text">
              <p>Use the subscription code <span class="highlight">MSSTUDENT</span> to access the Study Guide for free when creating your <span class="nowrap">student account.</span></p>
              
              <div class="teacher-access">
                <p><strong>Teachers: </strong> 
                  <a href="https://forms.gle/XkQLTA24XntHrp9u8" target="_gradus" rel="noopener noreferrer">Click here</a>
                  to request access to your complimentary <span class="nowrap">Teacher Account</span>, which includes essential teacher-only resources and special features.
                </p>
              </div>
            </div>

            <div class="action">
              <v-btn color="indigo" data-test-id="btnCreateAccount" class="white--text" @click="signup" large>Create account</v-btn>
              <v-btn color="indigo" data-test-id="btnLogIn" @click="login" large class="white--text">Log in</v-btn>
            </div>
          </div>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "Welcome",
  computed: {
    brandKey() {
      return window.BRAND_KEY;
    },
    mainStyle() {
      return {
        backgroundImage: `url('/brand/${this.brandKey}/login-image.jpg')`,
      };
    }
  },
  watch: {
    "$auth.isAuthenticated": {
      handler: function (val) {
        if (val) {
          this.$router.push("/").catch((err) => { /*don't care*/ });
        }
      },
      deep: true,
      immediate:true
    },
  },
  methods: {
    login() {
      this.$auth.doLogin();
    },
    signup() {
      this.$auth.doLogin({ screen_hint: 'signup' });
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px 64px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7);
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;

  .headerText {
    font-size: 3rem;
    font-weight: 800;
    color: rgba(0,0,0,0.75);
    margin-bottom: 16px;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  width: 100%;
}

.logo {
  position: absolute;
  top: 30px;
  height: 64px;
  width: auto;

  &.left {
    left: 30px;
  }

  &.right {
    right: 30px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.info-text {
  margin: 24px 0;
  // max-width: 600px;
  color: rgba(0,0,0,0.87);
  line-height: 1.6;

  .highlight {
    background: rgba(63, 81, 181, 0.1);
    color: #3f51b5;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .teacher-access {
    font-size: 1rem;
    
    a {
      color: #3f51b5;
      text-decoration: none;
      border-bottom: 1px solid currentColor;
      
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}
</style>
