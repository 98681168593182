<template>
  <v-card elevation="5">
    <v-card-title>
      <h2>Your strengths and weaknesses</h2>
    </v-card-title>
    <v-card-text>
      <p v-if="!visible">
        After you've completed a few assessments, we'll start building a picture of what your strengths and weaknesses are.<br/>
        They'll be shown here!
      </p>
      <div class="ma-sw" v-else>
        <div class="level" v-for="(level,idx) of levelTitles" :key="`level-${level}`">
          <h5 class="mb-2">{{ level }}</h5>
          <div v-if="tagsForLevel(5-idx)">
            <Tag v-for="tag of tagsForLevel(5-idx)" :key="`level-${level}-tag-${tag.slug}`" :tag="tag" @click="viewTag(tag.slug)" />
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {STRENGTH_LEVELS} from "../../../constants";
import Tag from "../components/Tag.vue";

export default {
  name: "StrengthsAndWeaknessesCard",
  components: {Tag},
  computed: {
    ...mapState('dashboard', ['data']),
    visible() {
      return this.data.strengthsWeaknesses.visible;
    },
    levelTitles() {
      return STRENGTH_LEVELS;
    },
  },
  methods: {
    tagsForLevel(level) {
      return this.data.strengthsWeaknesses.byLevel[`${level}`] || [];
    },
    viewTag(slug) {
      const route = {name: 'quizSubmissions', query: { 'filters.tag': slug }};
      console.log(route);
      this.$router.push(route);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style";

.ma-sw {
  display: flex;
  background: linear-gradient(90deg, transparentize(green, 0.7), transparentize(red, 0.7));
  border: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: $small-radius;
  padding: $pad 0;

  .level {
    flex: 1;
    text-align: center;
  }
}

</style>
