<template>
  <v-navigation-drawer app color="var(--v-primary-lighten4)" :value="drawerOpen">
    <div class="nav-product-logo">
      <img src="/logo/ma/square-inverse.svg" alt="MusicApprentice logo" />
      <br/>
      <span class="logotext logotext-bold">Music</span>
      <span class="logotext logotext-normal">Apprentice</span>
    </div>

    <v-list v-if="isAuthenticated">
      <Fragment v-for="item in navBarContent" :key="item.title">
        <v-list-item link v-if="!item.children" @click="navigateTo(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :data-test-id="idFor(item.title)">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-if="item.children" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title :data-test-id="idFor(item.title)">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="child in item.children" :key="child.title" link @click="navigateTo(child, item)">
            <v-list-item-title :data-test-id="idFor(item.title, child.title)">{{ child.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </Fragment>

    </v-list>

    <template v-slot:append>
      <div class="nav-company-logo" @click="navigateToGradus">
        <img src="/logo/gradus/square-white.svg" alt="Gradus Software logo" />
        <br/>
        <div class="logotext-small">Version {{ appVersion }} {{ appEnv }}</div>
        <div class="logotext-small"> &copy;2024 Gradus Software</div>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script>
import {Fragment} from "vue-fragment";
import PermissionsMixin from "../../common/permissions-mixin.js";
import {mapActions, mapState} from "vuex";
import {toTitleCaseForId} from "../../../utils/format.js";

export default {
  name: "NavBar",
  components: {Fragment},
  mixins: [ PermissionsMixin ],
  props: {
    drawerOpen: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState('app', ['appVersion', 'appEnv', 'appName', 'navBarContent']),
  },
  methods: {
    ...mapActions('ui', ['resetBreadcrumbs', "pushBreadcrumb"]),
    idFor(item, child) {
      return `nav${toTitleCaseForId(item)}${child ? toTitleCaseForId(child) : ''}`;
    },
    navigateTo(item) {
      this.resetBreadcrumbs({ title: item.title, route: item.link });
      this.$router.push(item.link).catch((err) => { /*don't care*/ });
    },
    navigateToGradus() {
      window.open('https://gradussoftware.com.au', '_gradus');
    }
  },
  watch: {
    isAuthenticated(newVal) {
      if(!newVal) {
        setInterval(() => window.location.reload(), 1000);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../style";

.nav-product-logo {
  text-align: center;
  background-color: $brand1;
  border-radius: 0 0 $large-radius $large-radius;
  margin-left: $pad;
  margin-right: $pad;
  padding: $pad;

  img {
    max-width: 48pt;
    max-height: 48pt;
  }

  .logotext {
    font-size: 18pt;

    &.logotext-normal {
      font-weight: 300;
      color: #ffffffdd;
    }

    &.logotext-bold {
      font-weight: 800;
      color: #ffffffdd;
    }
  }
}

.nav-company-logo {
  text-align: center;
  background-color: $brand2;
  border-radius: $large-radius $large-radius 0 0;
  margin-left: $pad;
  margin-right: $pad;
  padding: $pad;
  cursor: pointer;

  img {
    max-width: 4rem;
    max-height: 4rem;
  }

  .logotext {
    font-size: $base-font-size;
    font-weight: 600;
    color: #ffffffaa;
    text-transform: uppercase;
  }

  .logotext-small {
    color: #ffffffaa;
    font-size: $tiny-font-size;
  }
}

</style>