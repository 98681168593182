import { datadogRum } from '@datadog/browser-rum';

export function initDatadog() {
  datadogRum.init({
    applicationId: '4dd2383f-e74e-441f-a691-a70f2b6293e5',
    clientToken: 'pubf79222ab7488d945cffdb0d93c0784ac',
    site: 'ap1.datadoghq.com',
    service: 'musicapprentice-app',
    env: window.MATT_APP_ENV,
    version: window.MATT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}
